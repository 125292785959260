export namespace Preference {
    export const KEY = "preference";

    export enum Lab {
        InPlaceContainerEditing = "In-place Container Editing",
        DevMode = "Development Mode",
        WhosMyLegalContactFeature = "Whos My Legal Contact Feature",
        WhosMyLegalContactOptionsPhase1 = "Whos My Legal Contact Options Phase 1",
        WhosMyLegalContactOptionsPhase2 = "Whos My Legal Contact Options Phase 2",
        SharedPageLibrary = "SharedPageLibrary"
    }
    export class Value {
        userId: string;
        instanceId: string;
        labs: Set<Lab>;
    }
}